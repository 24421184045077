import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { Mesh, PlaneHelper } from 'three'
import gsap from 'gsap'

/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()
const parameters = {
    materialColor: '#ffeded'
}
let active = null
const ObjectDistance = 20
let texture = null;
//TEXTURES
// const loadingManager = new THREE.LoadingManager()
// loadingManager.onStart = () =>
// {
//     console.log('loading started')
// }
// loadingManager.onLoad = () =>
// {
//     console.log('loading finished')
// }
// loadingManager.onProgress = () =>
// {
//     console.log('loading progressing')
// }
// loadingManager.onError = () =>
// {
//     console.log('loading error')
// }
/**
 * Sizes
 */
 const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('load', () => {
    document.styleSheets[1].cssRules[3].style.top = ((sizes.height/3) )+'px'
})
window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()
    document.styleSheets[1].cssRules[3].style.top = ((sizes.height/3) )+'px'
    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})
/*
Cursor
*/
const cursor = {}
cursor.x = 0
cursor.y = 0
window.addEventListener('mousemove', (event) => {
    cursor.x = event.clientX / sizes.width - 0.5
    cursor.y = event.clientY / sizes.height - 0.5
    // console.log(cursor)
})
const contact = document.querySelector('.contact')
const about = document.querySelector('.about')
function selectView(x) {
   let contactclass = 'contact'
   let aboutclass = 'about'
   if (x === 'ABOUT') {
    contactclass += ' hide'
   }
   else if (x === 'BACK') {
    aboutclass += ' hide'
   }
   else {
    contactclass += ' hide'
    aboutclass += ' hide'
   }
   contact.className = contactclass
   about.className = aboutclass
   active = x
}
function clearView() {
    contact.className = 'contact hide'
    about.className = 'about hide'
}
function reset() {
    plane.position.x = 0
    plane.position.y = 0
    plane.position.z = 0
}
document.querySelector('.skills').addEventListener('click', () => {
    if (!camera)
        return
    if (active === 'ABOUT')
        return
    clearView()
    gsap.to(camera.position, {
        x:0,y:0,z:0,duration:2,onComplete: ()=> {
            if (active != 'HOME') {
                camera.lookAt(0,10,-95)
            }
            selectView('ABOUT')       
        }
    })
})
document.querySelector('.inside').addEventListener('click', () => {
    if (!camera)
        return
    if (active === 'SPHERE')
        return
    clearView()
    gsap.to(camera.position, {
        ...caminside, duration:3,onComplete:() =>{
            if (active != 'BACK')
                camera.lookAt(0,0,0)
            selectView('SPHERE')
        }
    })
})
document.querySelector('.back').addEventListener('click', () => {
    if (!camera)
        return
    if (active === 'BACK')
        return
    clearView()
    camera.position.x = 0
    camera.position.y = campos.y
    camera.position.z = campos.z
    reset()
    camera.lookAt(0,0,0)
    selectView('BACK')
})
document.querySelector('.clear').addEventListener('click', () => {
    if (!camera)
        return
    if (active === 'HOME')
        return
        clearView()
        if (active != 'ABOUT') {
            camera.position.x = startpos.x
            camera.position.y = startpos.y
            camera.position.z = startpos.z
            camera.lookAt(0,10,-95)
            selectView('HOME')
            return
        }
        gsap.to(camera.position, {...startpos, duration:2, onComplete: ()=> {
            if (active != 'ABOUT') {
                camera.lookAt(0,10,-95)
            }
            selectView('HOME')        
        }})
})
/**
 * Particles
 */
// Geometry
const particlesCount = 500
const positions = new Float32Array(particlesCount * 12)
for(let i = 0; i < particlesCount; i++)
{
    positions[i * 3 + 0] = (Math.random() - 0.5) *10
    positions[i * 3 + 1] = ObjectDistance * 0.5 - Math.random() * ObjectDistance * 2
    positions[i * 3 + 2] = (Math.random() - 0.5) *10
}
const particlesGeometry = new THREE.BufferGeometry()
particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
// Material
const particlesMaterial = new THREE.PointsMaterial({
    color: parameters.materialColor,
    sizeAttenuation: true,
    size: 0.03
})
// Points
const particles = new THREE.Points(particlesGeometry, particlesMaterial)
scene.add(particles)

// const textureLoader = new THREE.TextureLoader(loadingManager)
// const colorTexture = textureLoader.load('/textures/minecraft.png')
// const alphaTexture = textureLoader.load('/textures/door/alpha.jpg')
// const heightTexture = textureLoader.load('/textures/door/height.jpg')
// const normalTexture = textureLoader.load('/textures/door/normal.jpg')
// const ambientOcclusionTexture = textureLoader.load('/textures/door/ambientOcclusion.jpg')
// const metalnessTexture = textureLoader.load('/textures/door/metalness.jpg')
// const roughnessTexture = textureLoader.load('/textures/door/roughness.jpg')
// // colorTexture.repeat.x =.2
// // colorTexture.repeat.y = 3
// // colorTexture.wrapS = THREE.MirroredRepeatWrapping 
// // colorTexture.wrapT = THREE.MirroredRepeatWrapping 
// // colorTexture.offset.x = .5
// // colorTexture.offset.y = .5
// // colorTexture.rotation = Math.PI / 2
// // colorTexture.center.x = .5
// // colorTexture.center.y = .5
// colorTexture.generateMipmaps = false
// colorTexture.minFilter = THREE.NearestFilter
// colorTexture.magFilter = THREE.NearestFilter
/**
 * Object
 */
 const geometry = new THREE.PlaneGeometry( 10, 280 , 15, 150);
 const material = new THREE.MeshBasicMaterial( {color: '#c6f95e', side: THREE.DoubleSide, wireframe:true} );
//  const material = new THREE.MeshBasicMaterial({ map: colorTexture })
 const plane = new THREE.Mesh( geometry, material );
//  plane.rotation.y  
 plane.rotation.x += 2
 plane.position.y -= 0
// const geometry = new THREE.BoxBufferGeometry(1, 1, 1)
// const mesh = new THREE.Mesh(geometry, material)
scene.add(plane)


const circleGeo = new THREE.SphereGeometry(6, 20, 10 )
const materialw = new THREE.MeshBasicMaterial( {color: 0xffff00, wireframe:true} );

const circle = new THREE.Mesh(circleGeo, materialw)
circle.position.z =-75
circle.position.y = 44
circle.rotation.x += 0.5
scene.add(circle)

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)

let campos = {x: 0.05856267981319091, y: 49.928083137673646, z: -95.14933848015094}
let caminside = {x: -0.191930852634976, y: 45.116734415553076, z: -78.74934992130255}
const startpos = {x:0,y:10,z:0};
// camera.position.x = 0
// camera.position.y = 3
// camera.position.z = -6
camera.position.x = startpos.x
camera.position.y = startpos.y
camera.position.z = startpos.z
// camera.lookAt(0,49,-95)
scene.add(camera)

// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0
// var axis = new THREE.Vector3(0, 10, 0);
// var step = Math.PI * 0.0025;
const tick = () =>
{
    
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime
    // Update controls

    plane.position.z += 0.01/3
    plane.position.y -= 0.004/3
        // controls.update()
    // plane.position.z += 0.004
    // plane.position.y += 0.00101
    

    if (particles.position.y >= 36) {
        particles.position.y = -1
    }
    else
        particles.position.y += 0.007
    circle.rotation.y += 0.01
    const parallaxX = cursor.x
    const parallaxY = - cursor.y
    
    
    
    // camera.position.x += 0.1
    // camera.position.z -= 0.1
    // camera.position.applyAxisAngle(axis, step);

    particles.position.x +=((parallaxX - particles.position.x) * deltaTime*5)
    
    // particles.position.y += 0.1+((parallaxY - particles.position.y) * deltaTime*5)
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()
